const axios = require('axios')

import getUri from "./index"
import { EventBus } from '@/utils'

export const getAddresses = (jwt) => {
  try {
    return axios.post(getUri('api/addresses'), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getPageAddresses = (page, filters, jwt) => {
  try {
    return axios.post(getUri(`api/addresses?page=${page}`), filters, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

export const getAddress = (id, jwt) => {
  try {
    return axios.post(getUri(`api/address/${id}`), {}, { headers: { Authorization: `Bearer: ${jwt}` } })
  } catch (error) {
    if (error.response){
      if(error.response.status == 401){
        EventBus.$emit('logout')
      }
      if(error.response.status == 500){
        EventBus.$emit('error')
      }
      else{
        EventBus.$emit('fail')
      }
    }
    else{
      EventBus.$emit('fail')
    }
    return error
  }
}

// export const addAddress = (payload, jwt) => {
//   try {
//     return axios.put(getUri(`api/address`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
//   } catch (error) {
//     if (error.response){
//       if(error.response.status == 401){
//         EventBus.$emit('logout')
//       }
//       if(error.response.status == 500){
//         EventBus.$emit('error')
//       }
//       else{
//         EventBus.$emit('fail')
//       }
//     }
//     else{
//       EventBus.$emit('fail')
//     }
//     return error
//   }
// }

// export const updateAddress = (payload, jwt) => {
//   try {
//     return axios.post(getUri(`api/address`), payload, { headers: { Authorization: `Bearer: ${jwt}` } })
//   } catch (error) {
//     if (error.response){
//       if(error.response.status == 401){
//         EventBus.$emit('logout')
//       }
//       if(error.response.status == 500){
//         EventBus.$emit('error')
//       }
//       else{
//         EventBus.$emit('fail')
//       }
//     }
//     else{
//       EventBus.$emit('fail')
//     }
//     return error
//   }
// }