<template>
  <div class="app-form">
    <b-tabs>
      <b-tab-item label="Таблица">
    <!--     <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">ID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.id">
          </div>
        </div>
      </div>
      </div> -->
    <template v-for="(value, name) in model">
      <div class="field is-horizontal" v-bind:key="name" v-if="pass_keys.indexOf(name) < 0">
        <div class="field-label is-normal">
          <label class="label">{{name}}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input class="input" type="text" :disabled="true" :value="value">
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="field is-horizontal">
      <div class="field-label">
      </div>
      <!--       <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Сохранить
            </button>
          </div>
        </div>
        </div> -->
    </div>
    </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="model">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
  // import { getAddress, updateAddress} from '@/api/address'
  import { getAddress } from '@/api/address'
  import { state_yesno, state_show } from '@/utils'
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'
  export default {
    name: 'AddressForm',
    components: {
      VueJsonPretty
    },
    props: {
      //
    },
    data: function () {
      let pass_keys = ["client", "customer"]
      return {
        state_yesno: state_yesno,
        state_show: state_show,
        pass_keys: pass_keys,
        model: {
          id: null,
        }
      }
    },
    created: function() {
      //
    },
    computed: {
      //
    },
    beforeMount: function(){
      //
    },
    mounted: function(){
      // let $this = this
  
      // getRoles(this.$store.state.jwt_user)
      //   .then(response => {
      //     let data = response.data;
      //     data.data.forEach((item) => {
      //       this.roles.push(item)
      //     })
      //   })
      //   .catch(
      //     this.roles = []
      //   )
  
      getAddress(this.$route.params.id, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          
          let result = data.data
          this.model = result
  
        })
        .catch(
          this.model = {}
        )
    },
    methods: {
      // doUpdate () {
      //   let $this = this
      //   this.$buefy.dialog.confirm({
      //     message: "Вы уверены?",
      //     cancelText: "Нет",
      //     confirmText: "Да",
      //     onConfirm: () => {
            
      //       updateAddress($this.model, this.$store.state.jwt_user).then(() =>
      //         {
      //           EventBus.$emit('successUpdateUser')
      //           this.$router.push({ name: 'a-users', params: {} })
      //         }
      //       )        
  
      //     }
      //   })
      // },
    }
  }
</script>
<style scoped>
  .app-form {
  text-align: left;
  }
</style>
<style></style>